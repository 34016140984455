import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { db } from '../../../firebase/config';
import { collection, query, getDocs } from 'firebase/firestore';
import GaherisCharacterCreate from "../../../components/gaheris/characters/GaherisCharacterCreate";
import GaherisCharacterTable from "../../../components/gaheris/characters/GaherisCharacterTable";

const GaherisCharacters = () => {
    const { user } = useAuthContext();
    const [characters, setCharacters] = useState([]);

    const fetchCharacters = async () => {
        if (!user) return;
        try {
            const q = query(collection(db, `users/${user.uid}/gaheris`));
            const querySnapshot = await getDocs(q);
            const fetchedCharacters = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setCharacters(fetchedCharacters);
        } catch (error) {
            console.error('Error fetching characters:', error);
        }
    };

    useEffect(() => {
        fetchCharacters();
    }, [user]);


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={3} lg={3} xl={2}>
                <GaherisCharacterCreate
                    refreshCharacters={fetchCharacters}
                />
            </Grid>
            <React.Fragment>
                <Grid item xs={12} sm={8} md={9} lg={9} xl={10}>
                    <GaherisCharacterTable characters={characters} setCharacters={setCharacters} user={user} />
                </Grid>
            </React.Fragment>
        </Grid>
    );
};

export default GaherisCharacters;
