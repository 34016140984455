import React, { useState } from 'react';
import { Box, Typography, Divider, Paper, LinearProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const GaherisCharacterTableDetails = ({ details, realmRanks }) => {
    const theme = useTheme();

    const [maxSkillLevel, setMaxSkillLevel] = useState(1300); // Default max skill level

    const calculateRealmRank = (realmPoints) => {
        for (const rankInfo of realmRanks) {
            const { min_rp, max_rp, levels, rank } = rankInfo;

            if (rank === 14 && realmPoints >= min_rp) {
                return {
                    rank: 14,
                    level: 0,
                    progressPercent: 100,
                    nextLevelPoints: 0,
                    nextRankPoints: 0,
                    isMaxLevel: true,
                };
            }

            if (realmPoints >= min_rp && (max_rp === undefined || realmPoints < max_rp)) {
                if (!levels || levels.length === 0) {
                    return {
                        rank,
                        level: 0,
                        progressPercent: 0,
                        nextLevelPoints: max_rp - realmPoints,
                        nextRankPoints: max_rp - realmPoints,
                        isMaxLevel: false,
                    };
                }

                for (let i = 0; i < levels.length; i++) {
                    if (realmPoints >= levels[i] && (i + 1 === levels.length || realmPoints < levels[i + 1])) {
                        const levelProgress = ((realmPoints - levels[i]) / (levels[i + 1] - levels[i])) * 100;
                        const nextLevelPoints = levels[i + 1] - realmPoints;
                        const nextRankPoints = max_rp - realmPoints;

                        return {
                            rank,
                            level: i,
                            progressPercent: levelProgress,
                            nextLevelPoints,
                            nextRankPoints,
                            rankStartPoints: min_rp,
                            rankTotalPoints: max_rp - min_rp,
                            isMaxLevel: false,
                        };
                    }
                }
            }
        }

        return {
            rank: 0,
            level: 0,
            progressPercent: 0,
            nextLevelPoints: 0,
            nextRankPoints: 0,
            isMaxLevel: false,
        };
    };

    const realmWarStats = details.realm_war_stats?.current || {};
    const realmPoints = realmWarStats.realm_points || 0;
    const maxRealmPoints = realmRanks.find((rank) => rank.rank === 14)?.max_rp || 0;

    const rankAndLevel = calculateRealmRank(realmPoints);
    if (!rankAndLevel) return <Typography>No data available</Typography>;

    const { rank, level, progressPercent, nextLevelPoints, nextRankPoints, rankStartPoints, rankTotalPoints, isMaxLevel } = rankAndLevel;

    const craftingSkills = details.crafting || {}; // Example: { Alchemy: 750, Tailoring: 950, Weaponsmithing: 1200 }

    // Progress to the next realm rank
    const rankProgressPercent = ((realmPoints - rankStartPoints) / rankTotalPoints) * 100;

    // Total progress from RR1L0 to RR14L0
    const totalProgressPercent = (realmPoints / maxRealmPoints) * 100;

    // Helper function to render LinearProgress with centered text
    const renderProgressBar = (value, height = 20) => (
        <Box position="relative" display="flex" alignItems="center">
            <LinearProgress
                variant="determinate"
                value={value}
                style={{
                    height,
                    width: '100%',
                    borderRadius: 10,
                    backgroundColor: theme.palette.grey[300],
                }}
            />
            <Box
                position="absolute"
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="body2" style={{ color: 'black', fontWeight: 'bold' }}>
                    {`${Math.round(value)}%`}
                </Typography>
            </Box>
        </Box>
    );

    return (
        <Box padding={2}>
            <Typography variant="h6">Details for {details.name}</Typography>
            <Divider />
            <Box display="flex" justifyContent="space-between" marginTop={2} gap={2}>
                {/* Realm Rank Progress Box */}
                <Paper elevation={3} style={{ flex: 1, padding: '1rem', textAlign: 'center' }}>
                    <Typography variant="h6">Realm Rank Progress</Typography>
                    <Divider style={{ margin: '0.5rem 0' }} />
                    <Typography>{`Current Rank: RR${rank}L${level}`}</Typography>
                    <Box marginTop={2}>
                        <Typography variant="body2">Next Realm Level</Typography>
                        {renderProgressBar(progressPercent)}
                        <Typography marginTop={1}>{`${nextLevelPoints} RP to RR${rank}L${level + 1}`}</Typography>
                    </Box>
                    <Box marginTop={2}>
                        <Typography variant="body2">Next Realm Rank</Typography>
                        {renderProgressBar(rankProgressPercent)}
                        <Typography marginTop={1}>{`${nextRankPoints} RP to RR${rank + 1}L0`}</Typography>
                    </Box>
                    <Box marginTop={2}>
                        <Typography variant="body2">Total Progress</Typography>
                        {renderProgressBar(totalProgressPercent)}
                        <Typography marginTop={1}>{`${maxRealmPoints - realmPoints} RP to RR14L0`}</Typography>
                    </Box>
                </Paper>

                {/* Crafting Progress Box */}
                <Paper elevation={3} style={{ flex: 1, padding: '1rem', textAlign: 'center' }}>
                    <Typography variant="h6">Crafting Progress</Typography>
                    <Divider style={{ margin: '0.5rem 0' }} />
                    {Object.entries(craftingSkills).map(([skill, level]) => (
                        <Box key={skill} marginBottom={2}>
                            <Typography>{`${skill}: ${level}/${maxSkillLevel}`}</Typography>
                            {renderProgressBar((level / maxSkillLevel) * 100, 20)}
                        </Box>
                    ))}
                </Paper>

                {/* Guild Information Box */}
                <Paper elevation={3} style={{ flex: 1, padding: '1rem', textAlign: 'center' }}>
                    <Typography variant="h6">Information</Typography>
                    <Divider style={{ margin: '0.5rem 0' }} />
                    <Typography>Guild: {details.guild_info?.guild_name || 'No Guild'}</Typography>
                    <Typography>Master Level: {details.master_level?.level || 'No Master Level'} {details.master_level?.path || ''}</Typography>
                </Paper>
            </Box>
        </Box>
    );
};

export default GaherisCharacterTableDetails;
