import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import { db } from '../../firebase/config';
import { collection, addDoc } from 'firebase/firestore';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Snackbar,
    Alert,
    Container,
} from '@mui/material';

const realmToClasses = {
    Albion: ['Armsman', 'Cabalist', 'Cleric', 'Friar', 'Heretic', 'Infiltrator', 'Mauler', 'Mercenary',
        'Minstrel', 'Necromancer', 'Paladin', 'Reaver', 'Scout', 'Sorcerer', 'Theurgist', 'Wizard'],
    Midgard: ['Berserker', 'Bonedancer', 'Healer', 'Hunter', 'Mauler', 'Runemaster', 'Savage', 'Shadowblade',
        'Shaman', 'Skald', 'Spiritmaster', 'Thane', 'Valkyrie', 'Warlock', 'Warrior'],
    Hibernia: ['Animist', 'Bainshee', 'Bard', 'Blademaster', 'Champion', 'Druid', 'Eldritch', 'Enchanter', 'Hero',
        'Mauler', 'Mentalist', 'Nightshade', 'Ranger', 'Valewalker', 'Vampiir', 'Warden'],
};

const validLevels = [50];
const guideTypes = ["Class Guide", "Leveling Guide", "Returning Player"];

const CreateGuide = () => {
    const { user } = useAuthContext();
    const navigate = useNavigate();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [newGuideInfo, setNewGuideInfo] = useState({
        name: '',
        type: guideTypes[0], // Default to "Class Guide"
        realm: Object.keys(realmToClasses)[0], // Default realm
        class: realmToClasses[Object.keys(realmToClasses)[0]][0], // Default class
        level: validLevels[0], // Default level
    });
    const [classOptions, setClassOptions] = useState(realmToClasses[Object.keys(realmToClasses)[0]]);
    const [validationErrors, setValidationErrors] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');

    // 🔹 Hide component completely if the user is not a guide
    if (!user || user.role !== 'guide') {
        return null;
    }

    const handleCreateGuide = () => {
        setDialogOpen(true);
    };

    const createNewGuide = async () => {
        try {
            if (!user) return;

            const errors = {};
            if (!newGuideInfo.name.trim()) {
                errors.name = 'Name is required';
            } else if (newGuideInfo.name.length > 75) {
                errors.name = 'Name must be less than 75 characters';
            }

            if (Object.keys(errors).length > 0) {
                setValidationErrors(errors);
                return;
            }

            const currentDate = new Date();

            const guidesCollectionRef = collection(db, 'guides');
            const newGuideRef = await addDoc(guidesCollectionRef, {
                owner: user.uid,
                ownerName: user.displayName,
                ...newGuideInfo,
                isPrivate: true,
                createdOn: currentDate,
                lastUpdated: currentDate,
            });

            navigate(`/guides/${newGuideRef.id}`);
        } catch (error) {
            console.error('Error creating guide:', error);
        } finally {
            setDialogOpen(false);
        }
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setValidationErrors({});
    };

    const handleNewGuideInfoChange = (e) => {
        const { name, value } = e.target;

        if (name === 'type') {
            setNewGuideInfo((prevInfo) => ({
                ...prevInfo,
                [name]: value,
            }));
        } else if (name === 'realm') {
            setNewGuideInfo((prevInfo) => ({
                ...prevInfo,
                [name]: value,
                class: realmToClasses[value][0],
            }));
            setClassOptions(realmToClasses[value]);
        } else {
            setNewGuideInfo((prevInfo) => ({
                ...prevInfo,
                [name]: name === 'level' ? parseInt(value) : value,
            }));
        }
    };

    return (
        <Container>
            <Button variant="contained" color="primary" onClick={handleCreateGuide}>
                Create New Guide
            </Button>

            {/* Create New Guide Dialog */}
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Create New Guide</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {/* Guide Name */}
                        <Grid item xs={12}>
                            <TextField
                                label="Guide Name"
                                name="name"
                                value={newGuideInfo.name}
                                onChange={handleNewGuideInfoChange}
                                fullWidth
                                error={Boolean(validationErrors.name)}
                                helperText={validationErrors.name}
                                inputProps={{ maxLength: 75 }}
                            />
                        </Grid>

                        {/* Guide Type */}
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Guide Type</InputLabel>
                                <Select
                                    label="Guide Type"
                                    name="type"
                                    value={newGuideInfo.type}
                                    onChange={handleNewGuideInfoChange}
                                >
                                    {guideTypes.map((type) => (
                                        <MenuItem key={type} value={type}>
                                            {type}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Realm (Shown for Class Guide & Leveling Guide) */}
                        {(newGuideInfo.type === "Class Guide" || newGuideInfo.type === "Leveling Guide") && (
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Realm</InputLabel>
                                    <Select
                                        label="Realm"
                                        name="realm"
                                        value={newGuideInfo.realm}
                                        onChange={handleNewGuideInfoChange}
                                    >
                                        {Object.keys(realmToClasses).map((realm) => (
                                            <MenuItem key={realm} value={realm}>
                                                {realm}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}

                        {/* Class (Shown for Class Guide Only) */}
                        {newGuideInfo.type === "Class Guide" && (
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Class</InputLabel>
                                    <Select
                                        label="Class"
                                        name="class"
                                        value={newGuideInfo.class}
                                        onChange={handleNewGuideInfoChange}
                                    >
                                        {classOptions.map((className) => (
                                            <MenuItem key={className} value={className}>
                                                {className}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}

                        {/* Level (Shown for Class Guide Only) */}
                        {newGuideInfo.type === "Class Guide" && (
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Level</InputLabel>
                                    <Select
                                        label="Level"
                                        name="level"
                                        value={newGuideInfo.level}
                                        onChange={handleNewGuideInfoChange}
                                    >
                                        {validLevels.map((level) => (
                                            <MenuItem key={level} value={level}>
                                                {level}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant="contained" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={createNewGuide} variant="contained" color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default CreateGuide;
