import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase/config';
import { doc, getDoc } from 'firebase/firestore';
import { useAuthContext } from '../../hooks/useAuthContext';
import {
    Container,
    Typography,
    Card,
    CardContent,
    CircularProgress,
    Alert,
    Box,
    Chip,
    Button,
    useTheme,
    Fab,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MDEditor from '@uiw/react-md-editor';
import EditGuide from "../../components/guides/EditGuide";

const GuideDetails = () => {
    const { guideID } = useParams();
    const { user } = useAuthContext();
    const theme = useTheme();
    const [guide, setGuide] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [mdTheme, setMdTheme] = useState(theme.palette.mode);

    // Function to fetch guide data
    const fetchGuide = async () => {
        setLoading(true);
        try {
            const guideRef = doc(db, 'guides', guideID);
            const guideSnap = await getDoc(guideRef);
            if (guideSnap.exists()) {
                setGuide(guideSnap.data());
            } else {
                setError('Guide not found');
            }
        } catch (err) {
            setError('Error fetching guide');
            console.error(err);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchGuide();
    }, [guideID]);

    // Re-render when MUI theme mode changes
    useEffect(() => {
        setMdTheme(theme.palette.mode);
    }, [theme.palette.mode]);

    // Scroll to Top Function
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    if (loading) return <Container><CircularProgress /></Container>;
    if (error) return <Container><Alert severity="error">{error}</Alert></Container>;

    return (
        <Container maxWidth="xl">
            {isEditing ? (
                <EditGuide guide={guide} user={user} onExitEdit={() => { setIsEditing(false); fetchGuide(); }} />
            ) : (
                <Card sx={{ mt: 4, p: 2, backgroundColor: theme.palette.background.paper }}>
                    <CardContent>
                        {/* Edit Button (At the Top, Only for Guide Owner) */}
                        {user?.uid === guide.owner && (
                            <Box display="flex" justifyContent="flex-end" mb={2}>
                                <Button variant="contained" color="primary" onClick={() => setIsEditing(true)}>
                                    Edit Guide
                                </Button>
                            </Box>
                        )}

                        <Typography variant="h4" color="primary" gutterBottom>
                            {guide.name}
                        </Typography>

                        {/* Guide Type */}
                        <Chip label={guide.type} color="secondary" sx={{ mr: 1 }} />

                        {/* Realm (If applicable) */}
                        {guide.realm && <Chip label={guide.realm} color="primary" sx={{ mr: 1 }} />}

                        {/* Class (If applicable) */}
                        {guide.class && <Chip label={guide.class} color="success" sx={{ mr: 1 }} />}

                        {/* Level (If applicable) */}
                        {guide.level && <Chip label={`Level ${guide.level}`} color="default" sx={{ mr: 1 }} />}

                        <Box mt={2}>
                            <Typography variant="subtitle1" color="textSecondary">
                                Created by: {guide.ownerName}
                            </Typography>
                            <Typography variant="subtitle2" color="textSecondary">
                                Last Updated: {guide.lastUpdated ? new Date(guide.lastUpdated.toDate()).toLocaleString() : 'Unknown'}
                            </Typography>
                        </Box>

                        {/* Display Guide Content */}
                        <Box mt={2} data-color-mode={mdTheme} sx={{
                            backgroundColor: theme.palette.background.default,
                            color: theme.palette.text.primary,
                            padding: '10px',
                            borderRadius: '8px',
                            '& p': { margin: '0', padding: '0', lineHeight: '1.5' }, // Normalize paragraph spacing
                            '& h1, & h2, & h3, & h4, & h5, & h6': { margin: '16px 0 8px' }, // Adjust heading spacing
                            '& ul, & ol': { margin: '8px 0', paddingLeft: '20px' }, // Adjust list spacing
                            '& li': { marginBottom: '4px' }, // Reduce spacing in lists
                            '& pre': { backgroundColor: theme.palette.grey[900], padding: '8px', borderRadius: '5px' }, // Code block styling
                            '& blockquote': { borderLeft: `4px solid ${theme.palette.primary.main}`, paddingLeft: '10px', margin: '8px 0' } // Blockquote styling
                        }}>
                            {guide.content ? (
                                <MDEditor.Markdown
                                    source={guide.content}
                                    style={{ whiteSpace: 'normal' }} // Ensure line breaks render correctly
                                />
                            ) : (
                                <Typography variant="body1" color="textSecondary">No content available.</Typography>
                            )}
                        </Box>

                    </CardContent>
                </Card>
            )}

            {/* Scroll to Top Button */}
            <Fab
                color="primary"
                size="medium"
                sx={{
                    position: 'fixed',
                    bottom: 20,
                    right: 20,
                }}
                onClick={handleScrollToTop}
            >
                <KeyboardArrowUpIcon />
            </Fab>
        </Container>
    );
};

export default GuideDetails;
