import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Paper, Table, TableBody, TableCell, CircularProgress,
    TableContainer, TableHead, TableRow, TablePagination, TableSortLabel,
    TextField, Box, Checkbox, Button, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

const YourTemplates = ({ userTemplates, handleConfirmDelete, handleCreateTemplate, loading }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [sortField, setSortField] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);

    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;

    const handleBulkDelete = () => {
        if (selectedTemplates.length === 0) return;
        setDialogOpen(true); // Open confirmation dialog
    };

    const confirmBulkDelete = async () => {
        for (const templateId of selectedTemplates) {
            await handleConfirmDelete({ id: templateId });
        }
        setSelectedTemplates([]); // Clear selection after deletion
        setDialogOpen(false);
    };

    const handleCheckboxChange = (templateId) => {
        setSelectedTemplates(prev =>
            prev.includes(templateId)
                ? prev.filter(id => id !== templateId)
                : [...prev, templateId]
        );
    };

    const handleSelectAll = (event) => {
        setSelectedTemplates(event.target.checked ? userTemplates.map(t => t.id) : []);
    };

    const handleSortRequest = (property) => {
        const isAsc = sortField === property && sortOrder === 'asc';
        setSortField(property);
        setSortOrder(isAsc ? 'desc' : 'asc');
    };

    const filteredTemplates = searchTerm
        ? userTemplates.filter(template =>
            template.name.toLowerCase().includes(searchTerm) ||
            template.realm.toLowerCase().includes(searchTerm) ||
            template.class.toLowerCase().includes(searchTerm)
        )
        : userTemplates;

    const sortedTemplates = [...filteredTemplates].sort((a, b) => {
        if (a[sortField] < b[sortField]) return sortOrder === 'asc' ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortOrder === 'asc' ? 1 : -1;
        return 0;
    });

    return (
        <React.Fragment>
            <Box display="flex" justifyContent="space-between" marginBottom={2}>
                <TextField label="Search" variant="outlined" onChange={e => setSearchTerm(e.target.value.toLowerCase())} sx={{ marginRight: 2 }} />
                <Box>
                    {selectedTemplates.length > 0 && (
                        <Button variant="contained" color="secondary" onClick={handleBulkDelete} sx={{ marginRight: 2 }}>
                            Delete Selected ({selectedTemplates.length})
                        </Button>
                    )}
                    <Button variant="contained" color="primary" onClick={handleCreateTemplate}>
                        Create New Template
                    </Button>
                </Box>
            </Box>

            <TableContainer component={Paper}>
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    {/*<Checkbox*/}
                                    {/*    indeterminate={selectedTemplates.length > 0 && selectedTemplates.length < userTemplates.length}*/}
                                    {/*    checked={selectedTemplates.length === userTemplates.length}*/}
                                    {/*    onChange={handleSelectAll}*/}
                                    {/*/>*/}
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortField === 'name'}
                                        direction={sortField === 'name' ? sortOrder : 'asc'}
                                        onClick={() => handleSortRequest('name')}
                                    >
                                        Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortField === 'realm'}
                                        direction={sortField === 'realm' ? sortOrder : 'asc'}
                                        onClick={() => handleSortRequest('realm')}
                                    >
                                        Realm
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortField === 'level'}
                                        direction={sortField === 'level' ? sortOrder : 'asc'}
                                        onClick={() => handleSortRequest('level')}
                                    >
                                        Level
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortField === 'class'}
                                        direction={sortField === 'class' ? sortOrder : 'asc'}
                                        onClick={() => handleSortRequest('class')}
                                    >
                                        Class
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortField === 'isPrivate'}
                                        direction={sortField === 'isPrivate' ? sortOrder : 'asc'}
                                        onClick={() => handleSortRequest('isPrivate')}
                                    >
                                        Private
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortField === 'lastUpdated'}
                                        direction={sortField === 'lastUpdated' ? sortOrder : 'asc'}
                                        onClick={() => handleSortRequest('lastUpdated')}
                                    >
                                        Last Updated
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedTemplates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((template) => (
                                <TableRow key={template.id} selected={selectedTemplates.includes(template.id)}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selectedTemplates.includes(template.id)}
                                            onChange={() => handleCheckboxChange(template.id)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Link to={`/templates/${template.id}`} style={{ color: primaryColor }}>
                                            {template.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{template.realm}</TableCell>
                                    <TableCell>{template.level}</TableCell>
                                    <TableCell>{template.class}</TableCell>
                                    <TableCell>{template.isPrivate ? 'Private' : 'Public'}</TableCell>
                                    <TableCell>{new Date(template.lastUpdated.seconds * 1000).toLocaleDateString()}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={userTemplates.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
                />
            </TableContainer>

            {/* Confirmation Dialog */}
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete {selectedTemplates.length} selected templates? This action cannot be undone.
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} color="primary">Cancel</Button>
                    <Button onClick={confirmBulkDelete} color="secondary">Delete</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default YourTemplates;
