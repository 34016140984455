import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, FormControl, InputLabel, Grid, TableFooter, IconButton, Collapse, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteIcon from '@mui/icons-material/Delete';
import { db } from '../../../firebase/config';
import { doc, deleteDoc } from 'firebase/firestore';
import GaherisCharacterTableDetails from './GaherisCharacterTableDetails';
import realmRanks from '../../../data/realm_ranks.json';

const GaherisCharacterTable = ({ characters, setCharacters, user }) => {
    const [characterDetails, setCharacterDetails] = useState({});
    const [filteredCharacters, setFilteredCharacters] = useState([]);
    const [selectedRealm, setSelectedRealm] = useState('');
    const [selectedClass, setSelectedClass] = useState('');
    const [openCollapse, setOpenCollapse] = useState({}); // Keep track of collapsed rows

    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;

    const realmMapping = {
        1: 'Albion',
        2: 'Midgard',
        3: 'Hibernia'
    };

    const fetchCharacterData = async (characterId) => {
        try {
            const url = `https://api.camelotherald.com/character/info/${characterId}`;
            const response = await fetch(url);
            const data = await response.json();
            setCharacterDetails(prev => ({ ...prev, [characterId]: data }));
        } catch (error) {
            console.error('Error fetching character data:', error);
        }
    };

    useEffect(() => {
        characters.forEach(character => {
            fetchCharacterData(character.characterId);
        });
    }, [characters]);

    useEffect(() => {
        const filtered = characters.filter(character => {
            const details = characterDetails[character.characterId] || {};
            const realmName = realmMapping[details.realm];
            return (
                (!selectedRealm || realmName === selectedRealm) &&
                (!selectedClass || details.class_name === selectedClass)
            );
        });
        setFilteredCharacters(filtered);
    }, [characters, characterDetails, selectedRealm, selectedClass]);

    const handleCollapseToggle = (characterId) => {
        setOpenCollapse(prev => ({ ...prev, [characterId]: !prev[characterId] }));
    };

    const handleDeleteCharacter = async (characterId) => {
        try {
            const characterRef = doc(db, `users/${user.uid}/gaheris`, characterId);
            await deleteDoc(characterRef);
            setCharacters(prev => prev.filter(character => character.characterId !== characterId));
        } catch (error) {
            console.error('Error deleting character:', error);
        }
    };

    const calculateRealmRank = (realmPoints) => {
        for (const rankInfo of realmRanks) {
            const { min_rp, max_rp, levels, rank } = rankInfo;

            // Handle the last rank (RR14L0)
            if (rank === 14 && realmPoints >= min_rp) {
                return `14L0`; // Fixed at the max rank
            }

            if (realmPoints >= min_rp && (max_rp === undefined || realmPoints < max_rp)) {
                if (!levels || levels.length === 0) {
                    return `${rank}L0`; // Fallback for undefined or empty levels
                }

                for (let i = 0; i < levels.length; i++) {
                    // Determine the current level
                    if (realmPoints >= levels[i] && (i + 1 === levels.length || realmPoints < levels[i + 1])) {
                        return `${rank}L${i}`;
                    }
                }

                // If the realm points exceed the last level, move to the next rank
                if (realmPoints >= levels[levels.length - 1]) {
                    return `Rank ${rank + 1}L0`;
                }
            }
        }
        return ''; // Return empty string if no rank is found
    };



    const calculateTotals = (characters) => {
        return characters.reduce((totals, character) => {
            const details = characterDetails[character.characterId] || {};
            const realmWarStats = details.realm_war_stats?.current || {};

            totals.realmPoints += realmWarStats.realm_points || 0;

            return totals;
        }, {
            realmPoints: 0
        });
    };

    const totals = calculateTotals(filteredCharacters);

    const boldFooterStyle = { fontWeight: 'bold', fontSize: '1rem', color: primaryColor, borderRight: `1px solid ${primaryColor}` };

    return (
        <div>
            <Grid container spacing={2} marginBottom={2}>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel>Realm</InputLabel>
                        <Select value={selectedRealm} onChange={(e) => setSelectedRealm(e.target.value)}>
                            <MenuItem value=''>All Realms</MenuItem>
                            <MenuItem value='Albion'>Albion</MenuItem>
                            <MenuItem value='Midgard'>Midgard</MenuItem>
                            <MenuItem value='Hibernia'>Hibernia</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel>Class</InputLabel>
                        <Select value={selectedClass} onChange={(e) => setSelectedClass(e.target.value)}>
                            <MenuItem value=''>All Classes</MenuItem>
                            {Array.from(new Set(characters.map(character => characterDetails[character.characterId]?.class_name)))
                                .filter(Boolean)
                                .map(cls => (
                                    <MenuItem key={cls} value={cls}>{cls}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell style={{ color: primaryColor }}>Name</TableCell>
                            <TableCell style={{ color: primaryColor }}>Realm Rank</TableCell>
                            <TableCell style={{ color: primaryColor }}>Class</TableCell>
                            <TableCell style={{ color: primaryColor }}>Realm</TableCell>
                            <TableCell style={{ color: primaryColor }}>LVL</TableCell>
                            <TableCell style={{ color: primaryColor }}>Race</TableCell>
                            <TableCell style={{ color: primaryColor }}>Realm Points</TableCell>
                            <TableCell style={{ color: primaryColor }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredCharacters.map(character => {
                            const details = characterDetails[character.characterId] || {};
                            const realmWarStats = details.realm_war_stats?.current || {};
                            const realmRank = calculateRealmRank(realmWarStats.realm_points || 0);

                            return (
                                <React.Fragment key={character.id}>
                                    <TableRow>
                                        <TableCell>
                                            <IconButton
                                                onClick={() => handleCollapseToggle(character.characterId)}
                                                aria-label="expand row"
                                                size="small"
                                            >
                                                {openCollapse[character.characterId] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell style={{ color: primaryColor }}>{character.name}</TableCell>
                                        <TableCell style={{ color: primaryColor }}>{realmRank}</TableCell>
                                        <TableCell style={{ color: primaryColor }}>{details.class_name || character.class}</TableCell>
                                        <TableCell style={{ color: primaryColor }}>{realmMapping[details.realm]}</TableCell>
                                        <TableCell style={{ color: primaryColor }}>{details.level}</TableCell>
                                        <TableCell style={{ color: primaryColor }}>{details.race}</TableCell>
                                        <TableCell style={{ color: primaryColor }}>{realmWarStats.realm_points || 0}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="error"
                                                size="small"
                                                onClick={() => handleDeleteCharacter(character.characterId)}
                                                startIcon={<DeleteIcon />}
                                            >
                                                Remove
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                                            <Collapse in={openCollapse[character.characterId]} timeout="auto" unmountOnExit>
                                                <GaherisCharacterTableDetails
                                                    details={details}
                                                    realmRanks={realmRanks}
                                                />
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={8} style={boldFooterStyle}>Total</TableCell>
                            <TableCell style={boldFooterStyle}>{totals.realmPoints}</TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
};

export default GaherisCharacterTable;
