import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import CharactersList from '../../components/Characters/CharactersList';
import CharactersView from '../../components/Characters/CharactersView';
import { useAuthContext } from '../../hooks/useAuthContext';
import { db } from '../../firebase/config';
import { collection, query, getDocs } from 'firebase/firestore';
import OverView from '../../components/Characters/overview/OverView';

const Characters = () => {
    const { user } = useAuthContext();
    const [characters, setCharacters] = useState([]);
    const [selectedCharacter, setSelectedCharacter] = useState(null);
    const [selectedRealm, setSelectedRealm] = useState('');

    const fetchCharacters = async () => {
        if (!user) return;
        try {
            const q = query(collection(db, `users/${user.uid}/characters`));
            const querySnapshot = await getDocs(q);
            const fetchedCharacters = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setCharacters(fetchedCharacters);
        } catch (error) {
            console.error('Error fetching characters:', error);
        }
    };

    useEffect(() => {
        fetchCharacters();
    }, [user]);

    const handleRealmChange = (event) => {
        setSelectedRealm(event.target.value);
    };

    const handleCharacterSelect = (character) => {
        setSelectedCharacter(character);
    };

    const filteredCharacters = characters.filter(character => !selectedRealm || character.realm === selectedRealm);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={3} lg={3} xl={2} >
                <CharactersList
                    characters={filteredCharacters}
                    selectedRealm={selectedRealm}
                    onRealmChange={handleRealmChange}
                    onCharacterSelect={handleCharacterSelect}
                    selectedCharacterId={selectedCharacter ? selectedCharacter.id : null}
                    refreshCharacters={fetchCharacters}
                />
            </Grid>
            {selectedCharacter ? (
                <React.Fragment>
                    <Grid item xs={10} sm={10} md={9} lg={9} xl={10}>
                        <CharactersView character={selectedCharacter} />
                    </Grid>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Grid item xs={12} sm={8} md={9} lg={9} xl={10}>
                        <OverView characters={characters} user={user} setCharacters={setCharacters} />
                    </Grid>

                </React.Fragment>

            )}
        </Grid>
    );
};

export default Characters;
