import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    AppBar, Toolbar, Typography, Avatar, MenuItem, IconButton, Drawer, List, ListItem, Button,
    useMediaQuery, useTheme, Menu
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled, alpha } from '@mui/material/styles';
import { useLogout } from '../hooks/useLogout';
import { useAuthContext } from "../hooks/useAuthContext";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function Navbar({ themeMode, toggleThemeMode }) {
    const { logout } = useLogout();
    const { user } = useAuthContext();
    const [anchorEl, setAnchorEl] = useState(null);
    const [gaherisAnchorEl, setGaherisAnchorEl] = useState(null); // Gaheris dropdown state
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (menuItem) => {
        setAnchorEl(null);
        if (menuItem === 'Profile') {
            navigate('/profile');
        } else if (menuItem === 'Settings') {
            navigate('/settings');
        } else if (menuItem === 'Logout') {
            logout();
        }
    };

    const handleGaherisMenuOpen = (event) => {
        setGaherisAnchorEl(event.currentTarget);
    };

    const handleGaherisMenuClose = () => {
        setGaherisAnchorEl(null);
    };

    const handleMobileMenuOpen = () => {
        setMobileMenuOpen(true);
    };

    const handleMobileMenuClose = () => {
        setMobileMenuOpen(false);
    };

    const renderMobileMenuLink = (path, label) => (
        <ListItem onClick={handleMobileMenuClose} key={label}>
            <Button
                fullWidth
                component={Link}
                to={path}
                style={{ justifyContent: 'left', textTransform: 'none' }}
            >
                {label}
            </Button>
        </ListItem>
    );

    const mobileMenu = (
        <Drawer anchor="right" open={mobileMenuOpen} onClose={handleMobileMenuClose}>
            <List style={{ width: 250 }}>
                {user ? (
                    <>
                        <ListItem>
                            <Avatar
                                onClick={handleMenu}
                                src={user ? user.photoURL : undefined}
                                alt={user ? user.displayName : 'Avatar'}
                                style={{ cursor: 'pointer', marginRight: '16px' }}
                            />
                            <Typography
                                onClick={handleMenu}
                                style={{ cursor: 'pointer', marginRight: '16px' }}
                            >
                                {user ? user.displayName : ''}
                            </Typography>
                        </ListItem>
                        <hr />
                        {renderMobileMenuLink("/", "Home")}
                        {renderMobileMenuLink("/characters", "Characters")}
                        {renderMobileMenuLink("/mytemplates", "My templates")}
                        {renderMobileMenuLink("/templates", "Public templates")}
                        {renderMobileMenuLink("/shared", "Shared templates")}
                        {renderMobileMenuLink("/profile", "Profile")}
                        {renderMobileMenuLink("/settings", "Settings")}
                        <ListItem onClick={logout}>
                            <Button
                                fullWidth
                                style={{ justifyContent: 'left', textTransform: 'none' }}
                            >
                                Logout
                            </Button>
                        </ListItem>
                    </>
                ) : (
                    <>
                        {renderMobileMenuLink("/", "Home")}
                        {renderMobileMenuLink("/templates", "Public templates")}
                        <ListItem>
                            <Button
                                fullWidth
                                component={Link}
                                to="/login"
                                style={{ justifyContent: 'left', textTransform: 'none' }}
                                onClick={handleMobileMenuClose}
                            >
                                Login
                            </Button>
                        </ListItem>
                    </>
                )}
            </List>
        </Drawer>
    );

    return (
        <AppBar position="static" style={{ backgroundColor: 'primary' }}>
            <Toolbar>
                <Avatar
                    src="/daocbuddyfavicon.png"
                    alt="DAoC Buddy Logo"
                    style={{ marginRight: '10px' }}
                />
                <Typography variant="h6" style={{ flexGrow: 1 }}>
                    <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                        DAoC Buddy
                    </Link>
                </Typography>

                {isMobile ? (
                    <>
                        <IconButton onClick={toggleThemeMode} color="inherit" style={{ marginRight: '10px' }}>
                            {themeMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                        </IconButton>
                        <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleMobileMenuOpen}>
                            <MenuIcon />
                        </IconButton>
                    </>
                ) : (
                    <>
                        <Link
                            to="/characters"
                            style={{ textDecoration: 'none', color: 'inherit', marginRight: '16px' }}
                        >
                            My Characters
                        </Link>
                        <Link
                            to="/mytemplates"
                            style={{ textDecoration: 'none', color: 'inherit', marginRight: '16px' }}
                        >
                            My Templates
                        </Link>
                        <Link
                            to="/templates"
                            style={{ textDecoration: 'none', color: 'inherit', marginRight: '16px' }}
                        >
                            Public Templates
                        </Link>
                        <Link
                            to="/shared"
                            style={{ textDecoration: 'none', color: 'inherit', marginRight: '16px' }}
                        >
                            Shared Templates
                        </Link>
                        <Button
                            color="inherit"
                            onClick={handleGaherisMenuOpen}
                            endIcon={<KeyboardArrowDownIcon />}
                            style={{ textTransform: 'none', marginRight: '16px' }}
                        >
                            Gaheris
                        </Button>
                        <StyledMenu
                            anchorEl={gaherisAnchorEl}
                            open={Boolean(gaherisAnchorEl)}
                            onClose={handleGaherisMenuClose}
                        >
                            <MenuItem component={Link} to="/gaheris/characters" onClick={handleGaherisMenuClose}>
                                Characters
                            </MenuItem>
                            {/*<MenuItem component={Link} to="/gaheris/herald" onClick={handleGaherisMenuClose}>*/}
                            {/*    Herald*/}
                            {/*</MenuItem>*/}
                        </StyledMenu>
                        <IconButton onClick={toggleThemeMode} color="inherit" style={{ marginRight: '10px' }}>
                            {themeMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                        </IconButton>
                        <Avatar
                            onClick={handleMenu}
                            src={user?.photoURL || undefined}
                            alt={user?.displayName || 'Avatar'}
                            style={{ cursor: 'pointer', marginRight: '16px' }}
                        />
                        <Typography
                            onClick={handleMenu}
                            style={{ cursor: 'pointer', marginRight: '16px' }}
                        >
                            {user?.displayName}
                        </Typography>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={() => handleClose('Profile')}>Profile</MenuItem>
                            <MenuItem onClick={() => handleClose('Settings')}>Settings</MenuItem>
                            <MenuItem onClick={() => handleClose('Logout')}>Logout</MenuItem>
                        </Menu>
                    </>
                )}
            </Toolbar>
            {mobileMenu}
        </AppBar>
    );
}
