import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase/config';
import { collection, getDocs } from 'firebase/firestore';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TablePagination, TableSortLabel, Paper, CircularProgress, Box, Typography, Link
} from '@mui/material';

const GuidesTable = ({ selectedType, selectedRealm, selectedClass }) => {
    const [guides, setGuides] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortField, setSortField] = useState('lastUpdated');
    const [sortOrder, setSortOrder] = useState('desc');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchGuides = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'guides'));
                const fetchedGuides = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setGuides(fetchedGuides);
            } catch (err) {
                console.error('Error fetching guides:', err);
                setError('Failed to load guides.');
            }
            setLoading(false);
        };

        fetchGuides();
    }, []);

    const handleSort = (property) => {
        const isAsc = sortField === property && sortOrder === 'asc';
        setSortField(property);
        setSortOrder(isAsc ? 'desc' : 'asc');
    };

    const sortedGuides = [...guides]
        .filter(guide => {
            // Apply type filter (ignore if "All Guides" is selected)
            if (selectedType !== 'All Guides' && guide.type !== selectedType) return false;
            // Apply realm filter (if selected)
            if (selectedRealm && guide.realm !== selectedRealm) return false;
            // Apply class filter (if selected)
            if (selectedClass && guide.class !== selectedClass) return false;
            return true;
        })
        .sort((a, b) => {
            let valueA = a[sortField];
            let valueB = b[sortField];

            if (sortField === 'lastUpdated') {
                valueA = new Date(a.lastUpdated.toDate());
                valueB = new Date(b.lastUpdated.toDate());
            }

            return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
        });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (loading) return <Box display="flex" justifyContent="center"><CircularProgress /></Box>;
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <TableContainer component={Paper} sx={{ mt: 3 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={sortField === 'name'}
                                direction={sortField === 'name' ? sortOrder : 'asc'}
                                onClick={() => handleSort('name')}
                            >
                                Guide Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Created By</TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortField === 'lastUpdated'}
                                direction={sortField === 'lastUpdated' ? sortOrder : 'asc'}
                                onClick={() => handleSort('lastUpdated')}
                            >
                                Last Updated
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedGuides.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((guide) => (
                        <TableRow key={guide.id} hover onClick={() => navigate(`/guides/${guide.id}`)} style={{ cursor: 'pointer' }}>
                            <TableCell>
                                <Link href={`/guides/${guide.id}`} underline="hover">{guide.name}</Link>
                            </TableCell>
                            <TableCell>{guide.type}</TableCell>
                            <TableCell>{guide.ownerName}</TableCell>
                            <TableCell>{new Date(guide.lastUpdated.toDate()).toLocaleString()}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={sortedGuides.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
    );
};

export default GuidesTable;
