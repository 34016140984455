import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, storage } from '../../firebase/config';
import { doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { ref, listAll, deleteObject, uploadBytes, getDownloadURL } from 'firebase/storage';
import {
    Container,
    TextField,
    Button,
    Grid,
    Typography,
    Box,
    Snackbar,
    Alert,
    useTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import MDEditor from '@uiw/react-md-editor';

const EditGuide = ({ guide, user, onExitEdit }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { guideID } = useParams();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({ ...guide });
    const [imageUpload, setImageUpload] = useState(null);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    useEffect(() => {
        if (guide) {
            setFormData({ ...guide });
        }
    }, [guide]);

    // 🔹 Handle Markdown Content Change
    const handleContentChange = (value) => {
        setFormData((prev) => ({ ...prev, content: value }));
    };

    // 🔹 Handle Image Upload
    const handleImageUpload = async () => {
        if (!imageUpload) return;

        const imageRef = ref(storage, `guides/${guideID}/${imageUpload.name}`);
        try {
            const snapshot = await uploadBytes(imageRef, imageUpload);
            const downloadURL = await getDownloadURL(snapshot.ref);

            // Append image URL to the markdown content
            setFormData((prev) => ({
                ...prev,
                content: `${prev.content}\n\n![Image](${downloadURL})`, // Appends Markdown image
            }));

            setSnackbar({ open: true, message: 'Image uploaded successfully!', severity: 'success' });
        } catch (error) {
            console.error('Error uploading image:', error);
            setSnackbar({ open: true, message: 'Error uploading image.', severity: 'error' });
        }
    };

    // 🔹 Handle Guide Save
    const handleSubmit = async () => {
        setLoading(true);
        try {
            const guideRef = doc(db, 'guides', guideID);
            await updateDoc(guideRef, {
                ...formData,
                lastUpdated: new Date(),
            });

            setSnackbar({ open: true, message: 'Guide updated successfully!', severity: 'success' });
            onExitEdit(); // Exit edit mode and refresh guide
        } catch (error) {
            console.error('Error updating guide:', error);
            setSnackbar({ open: true, message: 'Failed to update guide.', severity: 'error' });
        }
        setLoading(false);
    };

    // 🔹 Delete Guide and Images
    const handleDeleteGuide = async () => {
        setLoading(true);
        try {
            const guideRef = doc(db, 'guides', guideID);

            // 🔸 Step 1: Delete all images in `guides/{guideID}/`
            const storageRef = ref(storage, `guides/${guideID}`);
            const images = await listAll(storageRef);

            for (const file of images.items) {
                await deleteObject(file);
            }

            // 🔸 Step 2: Delete guide from Firestore
            await deleteDoc(guideRef);

            setSnackbar({ open: true, message: 'Guide deleted successfully!', severity: 'success' });

            // 🔸 Step 3: Navigate back to guides list
            navigate('/guides');
        } catch (error) {
            console.error('Error deleting guide:', error);
            setSnackbar({ open: true, message: 'Failed to delete guide.', severity: 'error' });
        }
        setLoading(false);
    };

    return (
        <Container maxWidth="xl">
            <Typography variant="h4" color="primary" gutterBottom>Edit Guide</Typography>

            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={8}>
                    <TextField
                        label="Guide Title"
                        name="name"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        fullWidth
                    />
                </Grid>

                {/* Markdown Editor - Full Width with Max Width Constraint */}
                <Grid item xs={12}>
                    <Typography variant="h6">Guide Content</Typography>
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: '1500px',
                            margin: 'auto',
                            backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#fff',
                            color: theme.palette.text.primary,
                            borderRadius: '8px',
                            padding: '15px',
                            minHeight: '700px',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <MDEditor
                            value={formData.content}
                            onChange={handleContentChange}
                            height={700}
                            previewOptions={{
                                style: {
                                    backgroundColor: theme.palette.background.default,
                                    color: theme.palette.text.primary,
                                    padding: '10px',
                                    borderRadius: '8px',
                                }
                            }}
                        />
                    </Box>
                </Grid>

                {/* Image Upload */}
                <Grid item xs={12} md={8}>
                    <input type="file" onChange={(e) => setImageUpload(e.target.files[0])} />
                    <Button onClick={handleImageUpload} variant="contained" color="primary" disabled={!imageUpload} sx={{ ml: 1 }}>
                        Upload Image
                    </Button>
                </Grid>

                {/* Save, Back & Delete Buttons */}
                <Grid item xs={12} md={8}>
                    <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
                        {loading ? 'Saving...' : 'Save Changes'}
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={onExitEdit} sx={{ ml: 2 }}>
                        Back to View
                    </Button>
                    <Button variant="contained" color="error" onClick={() => setOpenDeleteDialog(true)} sx={{ ml: 2 }}>
                        Delete Guide
                    </Button>
                </Grid>
            </Grid>

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
                <DialogTitle>Delete Guide</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this guide? This will permanently remove the guide and all associated images.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteGuide} color="error">
                        Confirm Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default EditGuide;
