import React, { useState, useEffect } from 'react';
import { Button, Grid, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CreateGuide from '../../components/guides/CreateGuide';
import GuidesTable from '../../components/guides/GuidesTable';

const realmToClasses = {
    Albion: ['Armsman', 'Cabalist', 'Cleric', 'Friar', 'Heretic', 'Infiltrator', 'Mauler', 'Mercenary',
        'Minstrel', 'Necromancer', 'Paladin', 'Reaver', 'Scout', 'Sorcerer', 'Theurgist', 'Wizard'],
    Midgard: ['Berserker', 'Bonedancer', 'Healer', 'Hunter', 'Mauler', 'Runemaster', 'Savage', 'Shadowblade',
        'Shaman', 'Skald', 'Spiritmaster', 'Thane', 'Valkyrie', 'Warlock', 'Warrior'],
    Hibernia: ['Animist', 'Bainshee', 'Bard', 'Blademaster', 'Champion', 'Druid', 'Eldritch', 'Enchanter', 'Hero',
        'Mauler', 'Mentalist', 'Nightshade', 'Ranger', 'Valewalker', 'Vampiir', 'Warden'],
};

const guideTypes = ['All Guides', 'Class Guide', 'Leveling Guide', 'Returning Player'];

const Guides = () => {
    const navigate = useNavigate();
    const [selectedType, setSelectedType] = useState('All Guides');
    const [selectedRealm, setSelectedRealm] = useState('');
    const [selectedClass, setSelectedClass] = useState('');

    useEffect(() => {
        // Reset Realm and Class filters when Guide Type changes
        if (selectedType !== 'Class Guide') {
            setSelectedRealm('');
            setSelectedClass('');
        }
    }, [selectedType]);

    return (
        <Box p={2}>
            <CreateGuide />

            {/* Filter Selectors */}
            <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
                {/* Guide Type Selector */}
                <Grid item>
                    <FormControl sx={{ minWidth: 200 }}>
                        <InputLabel>Guide Type</InputLabel>
                        <Select value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
                            {guideTypes.map((type) => (
                                <MenuItem key={type} value={type}>{type}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                {/* Realm Selector (Only for Class Guides) */}
                {selectedType === 'Class Guide' && (
                    <Grid item>
                        <FormControl sx={{ minWidth: 150 }}>
                            <InputLabel>Realm</InputLabel>
                            <Select value={selectedRealm} onChange={(e) => setSelectedRealm(e.target.value)}>
                                <MenuItem value="">All Realms</MenuItem>
                                {Object.keys(realmToClasses).map((realm) => (
                                    <MenuItem key={realm} value={realm}>{realm}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}

                {/* Class Selector (Only for Class Guides & if a Realm is selected) */}
                {selectedType === 'Class Guide' && selectedRealm && (
                    <Grid item>
                        <FormControl sx={{ minWidth: 150 }}>
                            <InputLabel>Class</InputLabel>
                            <Select value={selectedClass} onChange={(e) => setSelectedClass(e.target.value)}>
                                <MenuItem value="">All Classes</MenuItem>
                                {realmToClasses[selectedRealm].map((className) => (
                                    <MenuItem key={className} value={className}>{className}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
            </Grid>

            {/* Guides Table with Filtering */}
            <GuidesTable selectedType={selectedType} selectedRealm={selectedRealm} selectedClass={selectedClass} />
        </Box>
    );
};

export default Guides;
