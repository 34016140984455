import { createContext, useReducer, useEffect } from 'react';
import { auth } from '../firebase/config';
import { onAuthStateChanged } from 'firebase/auth';
import { db } from '../firebase/config';
import { doc, getDoc } from 'firebase/firestore';

export const AuthContext = createContext();

export const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return { ...state, user: action.payload.user, token: action.payload.token };
        case 'LOGOUT':
            return { ...state, user: null, token: null };
        case 'AUTH_IS_READY':
            return {
                user: action.payload ? action.payload.user : null,
                authIsReady: true,
                token: action.payload ? action.payload.token : null
            };
        case 'SET_ROLE':
            return { ...state, user: { ...state.user, role: action.payload } };
        default:
            return state;
    }
};

export const AuthContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, {
        user: null,
        authIsReady: false,
        token: localStorage.getItem('authToken')
    });

    useEffect(() => {
        const unsub = onAuthStateChanged(auth, async (authUser) => {
            if (authUser) {
                const token = await authUser.getIdToken();
                localStorage.setItem('authToken', token);

                // Fetch user role from Firestore
                const userRef = doc(db, 'users', authUser.uid);
                const userSnap = await getDoc(userRef);

                const role = userSnap.exists() ? userSnap.data().role || '' : '';

                dispatch({
                    type: 'AUTH_IS_READY',
                    payload: { user: { ...authUser, role }, token }
                });
            } else {
                localStorage.removeItem('authToken');
                dispatch({ type: 'AUTH_IS_READY', payload: { user: null, token: null } });
            }
            unsub();
        });
    }, []);

    return (
        <AuthContext.Provider value={{ ...state, dispatch }}>
            { children }
        </AuthContext.Provider>
    );
};
