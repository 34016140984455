import React, { useEffect, useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem,
    FormControl, InputLabel, Grid, TableFooter, IconButton, Collapse, Button, TableSortLabel
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteIcon from '@mui/icons-material/Delete';
import { db } from '../../../firebase/config';
import { doc, deleteDoc } from 'firebase/firestore';
import OverViewCharacterDetails from "./OverViewCharacterDetails";
import realmRanks from '../../../data/realm_ranks.json';

const OverView = ({ characters, setCharacters, user }) => {
    const [characterDetails, setCharacterDetails] = useState({});
    const [filteredCharacters, setFilteredCharacters] = useState([]);
    const [selectedRealm, setSelectedRealm] = useState('');
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedServer, setSelectedServer] = useState('');
    const [openCollapse, setOpenCollapse] = useState({});
    const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' });

    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;
    const realmMapping = { 1: 'Albion', 2: 'Midgard', 3: 'Hibernia' };

    const fetchCharacterData = async (characterId) => {
        try {
            const url = `https://api.camelotherald.com/character/info/${characterId}`;
            const response = await fetch(url);
            const data = await response.json();
            setCharacterDetails(prev => ({ ...prev, [characterId]: data }));
        } catch (error) {
            console.error('Error fetching character data:', error);
        }
    };

    useEffect(() => {
        characters.forEach(character => {
            fetchCharacterData(character.characterId);
        });
    }, [characters]);

    useEffect(() => {
        const filtered = characters.filter(character => {
            const details = characterDetails[character.characterId] || {};
            const realmName = realmMapping[details.realm];
            return (
                (!selectedRealm || realmName === selectedRealm) &&
                (!selectedClass || details.class_name === selectedClass) &&
                (!selectedServer || character.server === selectedServer)
            );
        });
        setFilteredCharacters(filtered);
    }, [characters, characterDetails, selectedRealm, selectedClass, selectedServer]);

    const handleCollapseToggle = (characterId) => {
        setOpenCollapse(prev => ({ ...prev, [characterId]: !prev[characterId] }));
    };

    const handleDeleteCharacter = async (characterId) => {
        try {
            const characterToDelete = characters.find(character => character.characterId === characterId);
            if (!characterToDelete) {
                console.error('Character not found');
                return;
            }

            const characterRef = doc(db, `users/${user.uid}/characters`, characterToDelete.id);
            await deleteDoc(characterRef);

            setCharacters(prev => prev.filter(character => character.characterId !== characterId));
        } catch (error) {
            console.error('Error deleting character:', error);
        }
    };

    const calculateRealmRank = (realmPoints) => {
        for (const rankInfo of realmRanks) {
            const { min_rp, max_rp, levels, rank } = rankInfo;
            if (rank === 14 && realmPoints >= min_rp) return `14L0`;

            if (realmPoints >= min_rp && (max_rp === undefined || realmPoints < max_rp)) {
                if (!levels || levels.length === 0) return `${rank}L0`;

                for (let i = 0; i < levels.length; i++) {
                    if (realmPoints >= levels[i] && (i + 1 === levels.length || realmPoints < levels[i + 1])) {
                        return `${rank}L${i}`;  // 🔹 Corrected: No extra +1 to `i`
                    }
                }
            }
        }
        return '';
    };

    const availableClasses = Array.from(new Set(characters.map(character => characterDetails[character.characterId]?.class_name))).filter(Boolean);

    // Calculate Total Realm Points
    const totalRealmPoints = filteredCharacters.reduce((total, character) => {
        const details = characterDetails[character.characterId] || {};
        return total + (details.realm_war_stats?.current?.realm_points || 0);
    }, 0);

    // Sort Characters
    const sortedCharacters = [...filteredCharacters].sort((a, b) => {
        const detailsA = characterDetails[a.characterId] || {};
        const detailsB = characterDetails[b.characterId] || {};

        let valueA, valueB;

        if (sortConfig.key === 'name') {
            valueA = a.name.toLowerCase();
            valueB = b.name.toLowerCase();
        } else if (sortConfig.key === 'realmRank') {
            const rankA = calculateRealmRank(detailsA.realm_war_stats?.current?.realm_points || 0);
            const rankB = calculateRealmRank(detailsB.realm_war_stats?.current?.realm_points || 0);
            const [rankNumA, levelA] = rankA.split('L').map(Number);
            const [rankNumB, levelB] = rankB.split('L').map(Number);
            valueA = rankNumA * 10 + levelA;  // Ensures sorting is correct
            valueB = rankNumB * 10 + levelB;
        }

        if (valueA < valueB) return sortConfig.direction === 'asc' ? -1 : 1;
        if (valueA > valueB) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
    });

    const handleSort = (key) => {
        setSortConfig(prev => ({
            key,
            direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc',
        }));
    };

    return (
        <div>
            <Grid container spacing={2} marginBottom={2}>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel>Realm</InputLabel>
                        <Select value={selectedRealm} onChange={(e) => setSelectedRealm(e.target.value)} variant="outlined">
                            <MenuItem value=''>All Realms</MenuItem>
                            <MenuItem value='Albion'>Albion</MenuItem>
                            <MenuItem value='Midgard'>Midgard</MenuItem>
                            <MenuItem value='Hibernia'>Hibernia</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel>Class</InputLabel>
                        <Select value={selectedClass} onChange={(e) => setSelectedClass(e.target.value)} variant="outlined">
                            <MenuItem value=''>All Classes</MenuItem>
                            {availableClasses.map(cls => (
                                <MenuItem key={cls} value={cls}>{cls}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel>Server</InputLabel>
                        <Select value={selectedServer} onChange={(e) => setSelectedServer(e.target.value)} variant="outlined">
                            <MenuItem value=''>All Servers</MenuItem>
                            <MenuItem value='Ywain'>Ywain</MenuItem>
                            <MenuItem value='Gaheris'>Gaheris</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell style={{ color: primaryColor }}>
                                <TableSortLabel
                                    active={sortConfig.key === 'name'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('name')}
                                >
                                    Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ color: primaryColor }}>
                                <TableSortLabel
                                    active={sortConfig.key === 'realmRank'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('realmRank')}
                                >
                                    Realm Rank
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ color: primaryColor }}>Class</TableCell>
                            <TableCell style={{ color: primaryColor }}>Realm</TableCell>
                            <TableCell style={{ color: primaryColor }}>Server</TableCell>
                            <TableCell style={{ color: primaryColor }}>Race</TableCell>
                            <TableCell style={{ color: primaryColor }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedCharacters.map(character => {
                            const details = characterDetails[character.characterId] || {};
                            const realmRank = calculateRealmRank(details.realm_war_stats?.current?.realm_points || 0);

                            return (
                                <React.Fragment key={character.characterId}>
                                    <TableRow>
                                        <TableCell>
                                            <IconButton onClick={() => handleCollapseToggle(character.characterId)} size="small">
                                                {openCollapse[character.characterId] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>{character.name}</TableCell>
                                        <TableCell>{realmRank}</TableCell>
                                        <TableCell>{details.class_name || character.class}</TableCell>
                                        <TableCell>{realmMapping[details.realm]}</TableCell>
                                        <TableCell>{character.server}</TableCell>
                                        <TableCell>{details.race}</TableCell>
                                        <TableCell>
                                            <Button variant="contained" color="error" size="small" onClick={() => handleDeleteCharacter(character.characterId)} startIcon={<DeleteIcon />}>
                                                Remove
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={9} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                            <Collapse in={openCollapse[character.characterId]} timeout="auto" unmountOnExit>
                                                <OverViewCharacterDetails details={details} realmRanks={realmRanks} />
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            );
                        })}
                    </TableBody>

                        <TableRow>
                            <TableCell colSpan={7} style={{ color: primaryColor }}>Total Realm Points</TableCell>
                            <TableCell style={{ color: primaryColor }}>{totalRealmPoints}</TableCell>
                        </TableRow>
                </Table>
            </TableContainer>
        </div>
    );
};

export default OverView;
