import React, { useState } from 'react';
import { db } from '../../../firebase/config';
import { collection, query, where, getDocs, doc, getDocs as getSubDocs, updateDoc } from 'firebase/firestore';
import {
    Container,
    TextField,
    Button,
    Typography,
    Box,
    Card,
    CardContent,
    Chip
} from '@mui/material';

const Users = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [updating, setUpdating] = useState(false);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchSubmit = async (event) => {
        event.preventDefault();
        if (!searchTerm) return;

        setLoading(true);
        setUser(null);
        setError(null);

        try {
            // Query Firestore for a user with the exact displayName
            const usersCollection = collection(db, 'users');
            const q = query(usersCollection, where('displayName', '==', searchTerm));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                setError('No user found.');
                setLoading(false);
                return;
            }

            const userData = querySnapshot.docs[0]; // Assume first match
            const user = { id: userData.id, ...userData.data(), characters: [], gaheris: [] };

            // Fetch subcollections (characters & gaheris)
            const charactersCollection = collection(db, `users/${user.id}/characters`);
            const gaherisCollection = collection(db, `users/${user.id}/gaheris`);

            const [charactersSnapshot, gaherisSnapshot] = await Promise.all([
                getSubDocs(charactersCollection),
                getSubDocs(gaherisCollection)
            ]);

            user.characters = charactersSnapshot.docs.map(doc => doc.data().name || 'Unknown Character');
            user.gaheris = gaherisSnapshot.docs.map(doc => doc.data().name || 'Unknown Gaheris');

            setUser(user);
        } catch (error) {
            console.error('Error fetching user:', error);
            setError('Error fetching user.');
        }

        setLoading(false);
    };

    const handleToggleGuideRole = async () => {
        if (!user) return;

        setUpdating(true);
        try {
            const userRef = doc(db, 'users', user.id);
            const newRole = user.role === 'guide' ? '' : 'guide'; // Toggle role
            await updateDoc(userRef, { role: newRole });

            // Update local state to reflect the role change
            setUser(prev => ({ ...prev, role: newRole }));
        } catch (error) {
            console.error('Error updating role:', error);
            setError('Failed to update role.');
        }
        setUpdating(false);
    };

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" color="primary" gutterBottom>
                Search for a User
            </Typography>

            <form onSubmit={handleSearchSubmit}>
                <Box display="flex" gap={2} mb={3}>
                    <TextField
                        label="Enter Display Name"
                        variant="outlined"
                        fullWidth
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                    <Button type="submit" variant="contained" color="primary">
                        Search
                    </Button>
                </Box>
            </form>

            {loading && <Typography variant="h6">Loading...</Typography>}
            {error && <Typography color="error">{error}</Typography>}

            {user && (
                <Card sx={{ mt: 2, p: 2 }}>
                    <CardContent>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">{user.displayName}</Typography>
                            <Chip
                                label={user.admin ? 'Admin' : 'User'}
                                color={user.admin ? 'primary' : 'default'}
                            />
                        </Box>

                        <Box mt={2}>
                            <Typography variant="subtitle1">Role:</Typography>
                            <Chip
                                label={user.role === 'guide' ? 'Guide' : 'None'}
                                color={user.role === 'guide' ? 'success' : 'default'}
                            />
                        </Box>

                        {user.characters.length > 0 && (
                            <Box mt={2}>
                                <Typography variant="subtitle1">Characters:</Typography>
                                {user.characters.map((char, index) => (
                                    <Chip key={index} label={char} sx={{ mr: 1, mt: 1 }} />
                                ))}
                            </Box>
                        )}

                        {user.gaheris.length > 0 && (
                            <Box mt={2}>
                                <Typography variant="subtitle1">Gaheris:</Typography>
                                {user.gaheris.map((gah, index) => (
                                    <Chip key={index} label={gah} sx={{ mr: 1, mt: 1 }} />
                                ))}
                            </Box>
                        )}

                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color={user.role === 'guide' ? 'secondary' : 'success'}
                                onClick={handleToggleGuideRole}
                                disabled={updating}
                            >
                                {updating
                                    ? 'Updating...'
                                    : user.role === 'guide'
                                        ? 'Remove Guide Role'
                                        : 'Promote to Guide'}
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            )}
        </Container>
    );
};

export default Users;
