import React, { useState } from 'react';
import {
    Box,
    Typography,
    Divider,
    Paper,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableHead
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

const OverViewCharacterDetails = ({ details, realmRanks }) => {
    const theme = useTheme();
    const [maxSkillLevel] = useState(1300); // Default max crafting level

    const realmMapping = {
        1: 'Albion',
        2: 'Midgard',
        3: 'Hibernia'
    };

    const characterRealm = realmMapping[details.realm]; // Get character’s realm to hide its row

    const realmWarStats = details.realm_war_stats?.current || {};
    const realmPoints = realmWarStats.realm_points || 0;
    const bountyPoints = realmWarStats.bounty_points || 0;
    const playerKills = realmWarStats.player_kills || {};
    const maxRealmPoints = realmRanks.find(rank => rank.rank === 14)?.max_rp || 0;

    // Calculate Realm Rank
    const calculateRealmRank = (realmPoints) => {
        for (const rankInfo of realmRanks) {
            const { min_rp, max_rp, levels, rank } = rankInfo;

            // If the character has reached the final rank (RR14L0)
            if (rank === 14 && realmPoints >= min_rp) {
                return {
                    rank: 14,
                    level: 0,
                    progressPercent: 100,
                    nextLevelPoints: 0,
                    nextRankPoints: 0,
                    nextLevelLabel: `RR14L0`, // Stays at max rank
                    nextRankLabel: `RR14L0`,
                    isMaxLevel: true,
                };
            }

            // If the character is within this rank's range
            if (realmPoints >= min_rp && (max_rp === undefined || realmPoints < max_rp)) {
                if (!levels || levels.length === 0) {
                    return {
                        rank,
                        level: 0,
                        progressPercent: 0,
                        nextLevelPoints: max_rp - realmPoints,
                        nextRankPoints: max_rp - realmPoints,
                        nextLevelLabel: `RR${rank}L1`,
                        nextRankLabel: `RR${rank + 1}L0`,
                        isMaxLevel: false,
                    };
                }

                for (let i = 0; i < levels.length; i++) {
                    if (realmPoints >= levels[i] && (i + 1 === levels.length || realmPoints < levels[i + 1])) {
                        const isAtMaxLevel = i === levels.length - 1; // L9 is the max level

                        // ✅ Corrected Progress Calculation for L9
                        let nextLevelPoints;
                        let nextLevelLabel;
                        let levelProgressPercent;

                        if (isAtMaxLevel) {
                            nextLevelPoints = max_rp - realmPoints; // If at L9, next is RR(X+1)L0
                            nextLevelLabel = `RR${rank + 1}L0`;
                            levelProgressPercent = ((realmPoints - levels[i]) / (max_rp - levels[i])) * 100; // Adjusted for full rank
                        } else {
                            nextLevelPoints = levels[i + 1] - realmPoints;
                            nextLevelLabel = `RR${rank}L${i + 1}`;
                            levelProgressPercent = ((realmPoints - levels[i]) / (levels[i + 1] - levels[i])) * 100;
                        }

                        return {
                            rank,
                            level: i,
                            progressPercent: levelProgressPercent, // ✅ Fixes incorrect 100% at L9
                            nextLevelPoints,
                            nextRankPoints: max_rp - realmPoints,
                            nextLevelLabel,
                            nextRankLabel: `RR${rank + 1}L0`,
                            rankStartPoints: min_rp,
                            rankTotalPoints: max_rp - min_rp,
                            isMaxLevel: false,
                        };
                    }
                }
            }
        }

        return {
            rank: 0,
            level: 0,
            progressPercent: 0,
            nextLevelPoints: 0,
            nextRankPoints: 0,
            nextLevelLabel: `RR1L0`,
            nextRankLabel: `RR1L0`,
            isMaxLevel: false,
        };
    };






    const rankAndLevel = calculateRealmRank(realmPoints);
    const { rank, level, progressPercent, nextLevelPoints, nextRankPoints, rankStartPoints, rankTotalPoints } = rankAndLevel;

    // Progress bars
    const rankProgressPercent = ((realmPoints - rankStartPoints) / rankTotalPoints) * 100;
    const totalProgressPercent = (realmPoints / maxRealmPoints) * 100;

    const renderProgressBar = (value, height = 20) => (
        <Box position="relative" display="flex" alignItems="center">
            <LinearProgress variant="determinate" value={value} style={{ height, width: '100%', borderRadius: 10, backgroundColor: theme.palette.grey[300] }} />
            <Box position="absolute" width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
                <Typography variant="body2" style={{ color: 'black', fontWeight: 'bold' }}>{`${Math.round(value)}%`}</Typography>
            </Box>
        </Box>
    );

    // Crafting Skills
    const craftingSkills = details.crafting || {};

    // Filter out the character's own realm
    const filteredRealms = ["Albion", "Midgard", "Hibernia"].filter(realm => realm !== characterRealm);

    // Calculate totals
    const totalKills = filteredRealms.reduce((sum, realm) => sum + (playerKills[realm.toLowerCase()]?.kills || 0), 0);
    const totalDeathBlows = filteredRealms.reduce((sum, realm) => sum + (playerKills[realm.toLowerCase()]?.death_blows || 0), 0);
    const totalSoloKills = filteredRealms.reduce((sum, realm) => sum + (playerKills[realm.toLowerCase()]?.solo_kills || 0), 0);

    return (
        <Box padding={2}>
            <Typography variant="h6">Details for {details.name}</Typography>
            <Divider />

            <Box display="flex" justifyContent="space-between" marginTop={2} gap={2}>
                {/* Realm Rank Progress */}
                <Paper elevation={3} style={{ flex: 1, padding: '1rem', textAlign: 'center' }}>
                    <Typography variant="h6">Realm Rank Progress</Typography>
                    <Divider style={{ margin: '0.5rem 0' }} />
                    <Typography>{`Current Rank: RR${rank}L${level} (${realmPoints})`}</Typography>
                    <Box marginTop={2}>
                        <Typography variant="body2">Next Realm Level</Typography>
                        {renderProgressBar(progressPercent)}
                        <Typography marginTop={1}>{`${nextLevelPoints} RP to ${rankAndLevel.nextLevelLabel}`}</Typography>
                    </Box>
                    <Box marginTop={2}>
                        <Typography variant="body2">Next Realm Rank</Typography>
                        {renderProgressBar(rankProgressPercent)}
                        <Typography marginTop={1}>{`${nextRankPoints} RP to ${rankAndLevel.nextRankLabel}`}</Typography>
                    </Box>
                    <Box marginTop={2}>
                        <Typography variant="body2">Total Progress</Typography>
                        {renderProgressBar(totalProgressPercent)}
                        <Typography marginTop={1}>{`${maxRealmPoints - realmPoints} RP to RR14L0`}</Typography>
                    </Box>
                </Paper>

                {/* Crafting Progress */}
                <Paper elevation={3} style={{ flex: 1, padding: '1rem', textAlign: 'center' }}>
                    <Typography variant="h6">Crafting Progress</Typography>
                    <Divider style={{ margin: '0.5rem 0' }} />
                    {Object.entries(craftingSkills).map(([skill, level]) => (
                        <Box key={skill} marginBottom={2}>
                            <Typography>{`${skill.charAt(0).toUpperCase() + skill.slice(1)}: ${level}/${maxSkillLevel}`}</Typography>
                            {renderProgressBar((level / maxSkillLevel) * 100, 20)}
                        </Box>
                    ))}
                </Paper>

                {/* Character Info & Player Kills */}
                <Paper elevation={3} style={{ flex: 1, padding: '1rem', textAlign: 'left' }}>
                    <Typography variant="h6">Character Information</Typography>
                    <Divider style={{ margin: '0.5rem 0' }} />
                    <Typography><strong>Server:</strong> {details.server_name || 'Unknown'}</Typography>
                    <Typography><strong>Guild:</strong> {details.guild_info?.guild_name || 'No Guild'}</Typography>
                    <Typography><strong>Master Level:</strong> {details.master_level?.level || 'No ML'} {details.master_level?.path || ''}</Typography>
                    <Divider style={{ margin: '1rem 0' }} />
                    <Typography><strong>Current BP:</strong> {bountyPoints.toLocaleString()}</Typography>
                    <Divider style={{ margin: '1rem 0' }} />
                    {/* Player Kills Summary */}
                    <Typography variant="h6" style={{ marginTop: '1rem' }}>Kills</Typography>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Realm</strong></TableCell>
                                    <TableCell align="center"><strong>Kills</strong></TableCell>
                                    <TableCell align="center"><strong>Deaths</strong></TableCell>
                                    <TableCell align="center"><strong>Death Blows</strong></TableCell>
                                    <TableCell align="center"><strong>Solo Kills</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredRealms.map(realm => (
                                    <TableRow key={realm}>
                                        <TableCell><strong>{realm}</strong></TableCell>
                                        <TableCell align="center">{playerKills[realm.toLowerCase()]?.kills || 0}</TableCell>
                                        <TableCell align="center">{playerKills[realm.toLowerCase()]?.deaths || ""}</TableCell>
                                        <TableCell align="center">{playerKills[realm.toLowerCase()]?.death_blows || 0}</TableCell>
                                        <TableCell align="center">{playerKills[realm.toLowerCase()]?.solo_kills || 0}</TableCell>
                                    </TableRow>
                                ))}
                                <TableRow key="total">
                                    <TableCell><strong>Total</strong></TableCell>
                                    <TableCell align="center">{totalKills || 0}</TableCell>
                                    <TableCell align="center">{details.realm_war_stats.current.player_kills.total.deaths || 0}</TableCell>
                                    <TableCell align="center">{totalDeathBlows || 0}</TableCell>
                                    <TableCell align="center">{totalSoloKills || 0}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                    </TableContainer>
                </Paper>
            </Box>
        </Box>
    );
};

export default OverViewCharacterDetails;
